import { UseQueryOptions } from '@tanstack/react-query'
import { PictureShape, ResourcePictureView } from 'api'
import { AxiosError } from 'axios'
import { TranslationKey } from 'i18nKeys'

export type SnowflakeType = string

export type Id = number

export type SearchParamType = {
  query: FilterType[]
  paginate?: boolean
  order_by?: { [x: string]: 'ASC' | 'DESC' }
  limit?: number
}

export type FilterType = {
  field: string
  op: '=' | '<' | '>' | '<=' | '>=' | '!=' | 'IN' | 'CONTAINS'
  value: any
}

export type AvatarShape = 'round' | 'square' | 'rounded-square'

export const EMPTY_PICTURE: ResourcePictureView = {
  initial: 'X',
  shape: PictureShape.Round,
  url: null,
}

export enum TeamType {
  Sector = 'sector',
  Site = 'site',
}

export interface TeamShallow {
  id: Id
  type: TeamType
}

export interface Team extends TeamShallow {
  id: never
  snowflake: SnowflakeType
  name: string
  legacyId?: number
}

export interface TeamWithParent extends Team {
  parentSnowflake: SnowflakeType | null
}

export interface TeamFull {
  legacyId: number
  snowflake: SnowflakeType
  name: string
  parentSnowflake: SnowflakeType | null
}

export interface Resource {
  snowflake: SnowflakeType
  label: string
  picture: {
    shape: AvatarShape
    initial: string
    url: string | null
  }
  legacyId?: number
}

export interface ResourceWithTeams extends Resource {
  teams: {
    snowflake: SnowflakeType
    name: string
    parentSnowflake: SnowflakeType | null
  }[]
  // ignore this pls, it's a hack to make the bluefilter regroupment work
  isTeam?: boolean
  uniqueId?: string
}

export type Error = { hasError: boolean; message: string; errorCode?: number }

export type TranslationGroup = {
  [x: string]: TranslationKey
}

export type BySnowflake<T> = {
  [resourceSnowflake: string]: T
}

export type ById<T> = {
  [resourceId: string]: T
}

export type FetchingState = {
  isFetching: boolean
}

export type Component =
  | React.ComponentClass<any>
  | React.VoidFunctionComponent<any>
  | React.FunctionComponent<React.PropsWithChildren<any>>
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export type WithASnowflake = RequireAtLeastOne<any, 'snowflake'>

export type QueryError = {
  config: any
  request: any
  response: any
  message: string
}

export enum CounterVariableParameterType {
  COMPENSATION = 'Compensation',
  RESOURCE = 'Resource',
  TAG = 'Tag',
  ABSENCETYPE = 'AbsenceType',
  SCHEDULETEMPLATETYPE = 'ScheduleTemplateType',
  TEAM = 'Team',
}

type modulePermissionDetail = {
  status: 'string'
  permissions: { [x: string]: boolean }
}
type moduleNames =
  | 'activity'
  | 'calendar'
  | 'communication'
  | 'directory'
  | 'document'
  | 'dsi'
  | 'expenses'
  | 'planning'
  | 'quality'
  | 'timeclock'
  | 'whiteboard'

export type modulePermissions = {
  modules: {
    [M in moduleNames]: modulePermissionDetail
  }
}

// see https://stackoverflow.com/a/49670389
export type DeepReadonly<T> = T extends (infer R)[]
  ? DeepReadonlyArray<R>
  : T extends any
    ? T
    : T extends object
      ? DeepReadonlyObject<T>
      : T
export type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>
}
export type DeepReadonlyArray<T> = ReadonlyArray<DeepReadonly<T>>

export type NonUndefined<T> = T extends undefined ? never : T

/** Make the specified properties in T optional */
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type ValuesOf<T> = T[keyof T]

export type CustomUseQueryOptions<T> = Omit<UseQueryOptions<T, AxiosError, T, any>, 'queryKey' | 'queryFn'>

export type ArrayElement<T> = T extends (infer U)[] ? U : never

export type StringKeyof<T> = Extract<keyof T, string>

/** Like Omit but with strict typing on properties that you can remove (and TS errors if not present anymore on the original component). */
export type OmitStrict<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

declare const __nominal__type: unique symbol

export type Nominal<Type, Identifier> = Type & {
  readonly [__nominal__type]: Identifier
}

/**
 * To be used when having a list of possible strings (eg. ``'foo' | 'bar'``) and you want to add
 * the possibility of having any other string keeping intact the autocomplete
 */
export type AnyOtherString = string & NonNullable<unknown>

export type ApiOrderKeys<Q extends { orders?: { key: string }[] }> = Exclude<Q['orders'], undefined>[number]['key']
export type ApiFilterKeys<Q extends { filters?: { key: string }[] }> = Exclude<Q['filters'], undefined>[number]['key']

/**
 * Safe cast for types not to use `as` everywhere.

 * @example
 * ```ts
 * // works:
 * const a = makeSafeCaster<{ id: string }>()({ id: '123', somethig: 'else' })
 * const b = makeSafeCaster<{ id: string }>()({ id: '123' })
 * const c = [{ id: 'a', other: '...' }, { id: 'b', something: '...' }].map(makeSafeCaster<{ id: string }>())
 *
 * // doesn't work:
 * const d = makeSafeCaster<{ id: string }>()({ id: 123 })
 * ```
 *
 */
export const makeSafeCaster =
  <To>() =>
  // the double function is to make the type inference work
  <From>(value: From extends To ? From : never): From extends To ? To : never =>
    value as any
