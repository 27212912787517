import { TranslatedString } from 'api'
import { useBootstrap } from 'core/hooks/useBootstrap'
import { Locale } from 'core/i18n/types'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { z } from 'zod'

const hasTranslation = (translatedString: TranslatedString) => _.some(translatedString)

export const useTranslatedString = () => {
  const { config } = useBootstrap()
  const priorityList: Locale[] = config.multilingual_language
  const userLocale = config.active_language

  const isMultilingual = priorityList.length > 1

  //avoids that the user locale ovverides the priorityList if it's not in it
  const filteredPriorityList = useMemo((): Locale[] => {
    if (userLocale && priorityList.includes(userLocale)) {
      return [userLocale, ...priorityList.filter(locale => locale !== userLocale)]
    }
    return priorityList
  }, [priorityList, userLocale])

  //translates a TranslatedString based on the instance priority list and the user locale
  const translate = useCallback(
    (translatedString: TranslatedString, localeOverride?: Locale): string => {
      // check override
      if (localeOverride && translatedString[localeOverride]) {
        return translatedString[localeOverride]
      }

      // find the first non-empty translation in the priority list
      const trKey = filteredPriorityList.find(locale => translatedString[locale])
      if (trKey !== undefined) return translatedString[trKey] ?? ''

      // fallback to first available locale
      const availableKeys = Object.keys(translatedString) as Locale[]
      if (availableKeys.length > 0) return translatedString[availableKeys[0]] ?? ''

      return ''
    },
    [filteredPriorityList]
  )

  const flatten = (translatedString: TranslatedString): string[] => {
    return filteredPriorityList.map(locale => translatedString[locale] ?? '').filter(str => str !== '')
  }

  const fromUserString = (str: string): TranslatedString => ({ [filteredPriorityList[0]]: str })

  return {
    translate,
    hasTranslation,
    fromUserString,
    isMultilingual,
    priorityList: filteredPriorityList,
    flatten,
  }
}

export const z_translatedString = z.record(z.string())
export const z_nonEmptyTranslatedString = z_translatedString.refine(ts => _.some(ts))
export const z_nonEmptyTranslatedStringNullable = z_translatedString
  .transform(ts => (_.some(ts) ? ts : null))
  .nullable()
