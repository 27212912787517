import { parseDateTime } from 'chronos'
import { TeamWithParent } from 'core/types'
import { isValid } from 'date-fns'
import { PersonType, ResourceSummary, Shape } from 'directory/types'

const emptyResource = {
  id: '',
  label: '',
  picture: {
    url: '',
    initial: '',
    shape: Shape.Round,
  },
}

// TODO: remove when person migrated to resouurce (old api return problems)
export const formatPersonToResourceSummary = (person: PersonType): ResourceSummary | null => {
  return person
    ? {
        id: person.snowflake || '',
        label: person.general.full_name,
        picture: {
          shape: person.is_company
            ? Shape.Square
            : //@ts-expect-error
              ['thing', 'integration'].includes(person.role)
              ? Shape.RoundedSquare
              : Shape.Round,
          url: person.general.picture || null,
          initial: person.general.full_name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .charAt(0)
            .toLowerCase(),
        },
      }
    : emptyResource
}

export const validateDateRange = (dateRange: string) => {
  const validateDate = (date: string) => {
    if (date === '-') return true
    const tempDate = parseDateTime(date)
    if (isValid(tempDate)) return true
    return false
  }

  const dateParams = dateRange.split('/')

  const isDateValid = validateDate(dateParams[0]) && validateDate(dateParams[1])
  return isDateValid
}

// function to sort teams by parent's label, with the children of that parent after the parent
export const sortTeams = (teams: TeamWithParent[]) => {
  const sortedOnlyParentTeams = teams.filter(team => !team.parentSnowflake).sort((a, b) => a.name.localeCompare(b.name))
  const sortedTeams = sortedOnlyParentTeams.flatMap(parentTeam => {
    const children = teams
      .filter(childTeam => childTeam.parentSnowflake === parentTeam.snowflake)
      .sort((a, b) => a.name.localeCompare(b.name))
    return [parentTeam, ...children]
  })
  // if any team is left without parent, we add it at the end of the list
  const sortedTeamsWithoutParent = teams
    .filter(team => !sortedTeams.find(sortedTeam => sortedTeam.snowflake === team.snowflake))
    .sort((a, b) => a.name.localeCompare(b.name))
  return [...sortedTeams, ...sortedTeamsWithoutParent]
}
