import { authenticationService } from 'authentication/services/authentication'
import { sessionService } from 'authentication/services/session'
import { receiveAcls, receiveTwoFactorMethod, requestAcls } from 'authentication/state/actions'
import { areAclsExpired } from 'authentication/state/selectors'
import {
  REQUEST_ACLS,
  REQUEST_REMOVAL_TWO_FACTOR_METHOD,
  REQUEST_TWO_FACTOR_METHOD,
  RequestAclsAction,
  requestRemovalTwoFactorMethod,
  RequestTwoFactorMethod,
  SAVE_ACTIVE_LANGUAGE,
  SAVE_PREFERENCE,
  SAVE_SESSION,
  SaveActiveLanguage,
  SavePreferenceAction,
  SaveSessionAction,
  USER_LOGGED_OUT,
} from 'authentication/state/types'
import { parseDateTime } from 'chronos'
import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { addMinutes } from 'date-fns'
import { eventChannel } from 'redux-saga'
import { all, put, select, takeEvery, takeLatest } from 'typed-redux-saga'

function refreshAcls() {
  return eventChannel(emitter => {
    const iv = setInterval(
      () => {
        emitter({})
      },
      1000 * 60 * 10
    )

    /* istanbul ignore next */
    return () => {
      clearInterval(iv)
    }
  })
}

function* fetchAcls(action: RequestAclsAction) {
  const expired = yield* select(areAclsExpired)
  const { force } = action.payload

  if (!expired && !force) {
    return
  }

  try {
    const acls = yield* callApi(authenticationService.getAuthMatrix)
    const expiration = addMinutes(parseDateTime(), 10)
    yield* put(receiveAcls(acls, expiration))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* saveActiveLanguage(action: SaveActiveLanguage) {
  const { language } = action.payload

  try {
    yield* callApi(authenticationService.savePreference, 'language', language)
    window.location.reload()
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* savePreference(action: SavePreferenceAction) {
  const { preference, value } = action.payload

  try {
    yield* callApi(authenticationService.savePreference, preference, value)
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* saveSession(action: SaveSessionAction) {
  const { key, value } = action.payload

  try {
    yield* callApi(sessionService.saveSession, key, value)
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* logoutSaga() {
  try {
    window.location.href = '/auth/logout'
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export function* removeTwoFactorMethod(action: requestRemovalTwoFactorMethod) {
  try {
    const { resourceSnowflake, methodId } = action.payload
    yield* callApi(authenticationService.removeTwoFactorMethod, methodId)
    const fetchAction: RequestTwoFactorMethod = {
      type: 'REQUEST_TWO_FACTOR_METHOD',
      payload: {
        userId: resourceSnowflake,
      },
    }
    yield* callApi(fetchTwoFactorMethod, fetchAction)
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export function* fetchTwoFactorMethod(action: RequestTwoFactorMethod) {
  try {
    const twoFactorMethod = yield* callApi(authenticationService.fetchTwoFactorMethod, action.payload.userId)
    yield* put(receiveTwoFactorMethod(action.payload.userId, twoFactorMethod))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export const authenticationSaga = function* authenticationSaga() {
  const channel = yield* callApi(refreshAcls)
  yield* takeEvery(channel, function* () {
    yield* put(requestAcls(true))
  })

  all([
    yield* takeLatest(REQUEST_ACLS, fetchAcls),
    yield* takeLatest(USER_LOGGED_OUT, logoutSaga),
    yield* takeEvery(SAVE_ACTIVE_LANGUAGE, saveActiveLanguage),
    yield* takeEvery(SAVE_PREFERENCE, savePreference),
    yield* takeEvery(SAVE_SESSION, saveSession),
    yield* takeLatest(REQUEST_TWO_FACTOR_METHOD, fetchTwoFactorMethod),
    yield* takeLatest(REQUEST_REMOVAL_TWO_FACTOR_METHOD, removeTwoFactorMethod),
  ])
}
