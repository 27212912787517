import { ValidationError } from 'core/services/api'
import { SnowflakeType } from 'core/types'
import { CrudService } from 'crud/services/CrudService'

export const INIT_CRUD = 'INIT_CRUD'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const GO_TO_PAGE = 'GO_TO_PAGE'
export const REFRESH_PAGE = 'REFRESH_PAGE'
export const CHANGE_PER_PAGE = 'CHANGE_PER_PAGE'
export const ADD_SORT_FIELD = 'ADD_SORT_FIELD'
export const PAGE_RECEIVED = 'PAGE_RECEIVED'
export const PERFORM_OPERATION = 'PERFORM_OPERATION'
export const PERFORM_OPERATION_SUCCESS = 'PERFORM_OPERATION_SUCCESS'
export const PERFORM_OPERATION_FAILED = 'PERFORM_OPERATION_FAILED'

export type CrudId = number | SnowflakeType
export interface Entity {
  id: CrudId
  [x: string]: any
}

export interface EntityCollection {
  [id: CrudId]: Entity
}

export interface ServiceEnabledAction<T> {
  type: T
  meta: {
    entityName: string
    service: CrudService
  }
  payload: object
}

interface InitCrudAction {
  type: typeof INIT_CRUD
  payload: {
    entityName: string
  }
}

interface UpdateFilterAction extends ServiceEnabledAction<typeof UPDATE_FILTER> {
  payload: {
    filter: string
  }
}

interface GoToPageAction extends ServiceEnabledAction<typeof GO_TO_PAGE> {
  payload: {
    page: number
  }
}

type RefreshPageAction = ServiceEnabledAction<typeof REFRESH_PAGE>
interface ChangePerPageAction extends ServiceEnabledAction<typeof CHANGE_PER_PAGE> {
  payload: {
    perPage: number
  }
}

interface AddSortFieldAction extends ServiceEnabledAction<typeof ADD_SORT_FIELD> {
  payload: {
    field: string
    order: 'asc' | 'desc'
  }
}

export interface PageReceivedAction extends ServiceEnabledAction<typeof PAGE_RECEIVED> {
  payload: {
    entities: Entity[]
    total: number
  }
}

export interface PerformOperationAction extends ServiceEnabledAction<typeof PERFORM_OPERATION> {
  payload: {
    operation: string
    args: any[]
  }
}

export interface PerformOperationSuccessAction extends ServiceEnabledAction<typeof PERFORM_OPERATION_SUCCESS> {
  payload: {
    operation: string
    args: any[]
    result: object
  }
}

interface PerformOperationFailedAction extends ServiceEnabledAction<typeof PERFORM_OPERATION_FAILED> {
  payload: {
    operation: string
    args: any[]
    errors: ValidationError[]
  }
}

export type CrudActionTypes =
  | InitCrudAction
  | UpdateFilterAction
  | GoToPageAction
  | RefreshPageAction
  | ChangePerPageAction
  | AddSortFieldAction
  | PageReceivedAction
  | PerformOperationAction
  | PerformOperationSuccessAction
  | PerformOperationFailedAction

export type CrudFetchableActionTypes =
  | RefreshPageAction
  | GoToPageAction
  | ChangePerPageAction
  | AddSortFieldAction
  | PerformOperationSuccessAction

export type CrudSetup = {
  currentPage: number
  perPage: number
  totalPages: number
  total: number
  sortField: string | null
  sortOrder: 'asc' | 'desc' | null
  isProcessing: boolean
  isFetchingEntity: boolean
  errors: ValidationError[]
  pages: {
    [key: number]: {
      ids: CrudId[]
      isFetching: boolean
    }
  }
  filter: string
}

export interface CrudState {
  [key: string]: CrudSetup
}
