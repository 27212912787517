import { TranslatedString } from 'api'
import { useTranslatedString } from 'core/hooks/useTranslatedString'
import { Locale } from 'core/i18n/types'
import { createContext, useContext, useState } from 'react'

let translatedStringUtils: ReturnType<typeof useTranslatedString> | undefined

export const getTranslatedStringUtils = () => {
  if (!translatedStringUtils) {
    throw new Error('TranslatedStringUtils not initialized')
  }
  return translatedStringUtils
}

export type MultilingualContextType = {
  locale: Locale
  setLocale: (locale: Locale) => void
  translateInContext: (ts: TranslatedString) => string
  hasTranslationInContext: (ts: TranslatedString) => boolean
}

const MultilingualContext = createContext<MultilingualContextType>({} as MultilingualContextType)

export function MultilingualProvider({ children }: { children: React.ReactNode }) {
  const utils = useTranslatedString()

  // leak utils out of react context
  translatedStringUtils = utils

  const baseLocale = utils.priorityList[0]
  const [locale, setLocale] = useState<Locale>(baseLocale)

  return (
    <MultilingualContext.Provider
      value={{
        locale,
        setLocale,
        translateInContext: (ts: TranslatedString) => utils.translate(ts, locale),
        hasTranslationInContext: (ts: TranslatedString) => Boolean(ts[locale]),
      }}
    >
      {children}
    </MultilingualContext.Provider>
  )
}

export const useMultilingual = () => useContext(MultilingualContext)
